import type { InjectionKey } from 'vue'
import { z } from 'zod'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { postTutorialCompleteResponse } from '@/composables/repository/useTutorialRepository'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

// models
import { ProfileData } from '@/models/profiles'
import { StepperType } from '@/models/ui'

export const tutorialStepType = z.union([
  z.literal('avatar'),
  z.literal('room'),
  z.literal('decorate'),
])
export type TutorialStepType = z.infer<typeof tutorialStepType>

export const tutorialState = z.object({
  avatar: z.string().nullable(), // アバターのUUID
  avatarId: z.number().nullable(), // アバターのID
  room: z.number().nullable(),
  step: tutorialStepType,
  isConfirmOverwriteTutorial: z.boolean(),
})
export type TutorialState = z.infer<typeof tutorialState>

export const useTutorial = () => {
  const repositoryFactory = useRepositoryFactory()
  const tutorialRepository = repositoryFactory.get('tutorial')

  const i18n = useI18n()
  const { localePath } = useLocale()
  const state = useState<TutorialState>('tutorial-state', () => ({
    avatar: null,
    avatarId: null,
    room: null,
    step: 'avatar', // 1ステップ目はアバター選択
    isConfirmOverwriteTutorial: false,
  }))

  /** チュートリアルの各ステップ(この配列の順に導線が張られる) */
  const tutorialSteps: StepperType[] = [
    {
      id: 1,
      step: 'avatar',
      title: i18n.locale.value === 'ja' ? 'アバター' : 'Avatar',
    },
    {
      id: 2,
      step: 'room',
      title: i18n.locale.value === 'ja' ? 'ルーム' : 'Room',
    },
    {
      id: 3,
      step: 'decorate',
      title: i18n.locale.value === 'ja' ? '完了' : 'Completed',
    },
  ]

  /** 現在のチュートリアルのステップ */
  const activeStep = computed(() => {
    const step = tutorialSteps.find((step) => {
      return step.step === state.value.step
    })
    if (step) {
      return step
    } else {
      return tutorialSteps[0]!
    }
  })

  /** チュートリアルの先頭のパス */
  const firstStepPath = computed(() => {
    if (!tutorialSteps[0]) {
      return localePath('/')
    }
    return localePath(`/tutorial/${tutorialSteps[0].step}`)
  })

  /** 前のステップ */
  const prevStep = computed(() => {
    if (!activeStep.value) {
      return
    }

    const prevStep = tutorialSteps[tutorialSteps.indexOf(activeStep.value) - 1]
    if (!prevStep) {
      return
    }

    return prevStep
  })

  /** 次のステップのページパス */
  const nextStep = computed(() => {
    if (!activeStep.value) {
      return
    }

    const nextStep = tutorialSteps[tutorialSteps.indexOf(activeStep.value) + 1]
    if (!nextStep) {
      return
    }

    return nextStep
  })

  const setStep = (step: TutorialStepType) => {
    state.value.step = step
    setLocalStorageValue('tutorialValues', JSON.stringify(state.value))
  }

  const selectAvatar = (avatarId: number, avatarUuid: string) => {
    state.value.avatarId = avatarId
    state.value.avatar = avatarUuid
    setLocalStorageValue('tutorialValues', JSON.stringify(state.value))
  }

  const selectRoom = (roomId: number) => {
    state.value.room = roomId
    setLocalStorageValue('tutorialValues', JSON.stringify(state.value))
  }

  /** 警告を表示/非表示*/
  const confirmOverwriteTutorial = (value: boolean) => {
    state.value.isConfirmOverwriteTutorial = value
  }

  const postTutorialComplete = async (
    topPageAvatarId: number,
    presetHousingHomeId: number
  ): Promise<ProfileData> => {
    try {
      const response = await tutorialRepository.post.postTutorialComplete({
        topPageAvatarId,
        presetHousingHomeId,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(postTutorialCompleteResponse, response)) {
        console.error('An API response is different.')
      }

      return response.profile || null
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // SSRではローカルストレージにアクセスできないためonBeforeMountで初期化
  onBeforeMount(() => {
    const lsValue = getLocalStorageValue('tutorialValues')
    if (lsValue === null) {
      // ローカルストレージに値がない場合は初期値をセット
      setLocalStorageValue('tutorialValues', JSON.stringify(state.value))
      return
    }

    const parsedValue = JSON.parse(lsValue)
    if (isValueOf(tutorialState, parsedValue)) {
      // ローディングステータスは初期化する
      state.value = {
        ...parsedValue,
        isConfirmOverwriteTutorial: false,
      }
    } else {
      // ローカルストレージに値があるが、型が合わない場合は初期値をセット
      setLocalStorageValue('tutorialValues', JSON.stringify(state.value))
    }
  })

  return {
    state: readonly(state),
    activeStep,
    tutorialSteps,
    firstStepPath,
    prevStep,
    nextStep,
    setStep,
    selectAvatar,
    selectRoom,
    confirmOverwriteTutorial,
    postTutorialComplete,
  }
}

export type TutorialComposable = ReturnType<typeof useTutorial>

export const tutorialInjectionKey: InjectionKey<TutorialComposable> =
  Symbol('tutorial')
