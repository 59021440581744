import type { InjectionKey } from 'vue'

// modules
import { isValueOf } from '@/utils/zod'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  getPresetHousingHomesResponse,
  GetPresetHousingHomesRequest,
  getPresetHousingHomeResponse,
  getPresetHousingScenesResponse,
} from '@/composables/repository/useHomeRepository'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

// models
import { PresetHousingHome } from '@/models/presetHousingHome'
import { PresetHousingScene } from '@/models/presetHousingScene'

export const useHome = () => {
  const repositoryFactory = useRepositoryFactory()
  const homeRepository = repositoryFactory.get('home')

  type HousingState = {
    presetHousingHomes: PresetHousingHome[] // プリセットハウジングHOMEの一覧
    presetHousingScenes: PresetHousingScene[] // プリセットハウジングシーン一覧
    presetHousingHome: PresetHousingHome | null // プリセットハウジングHOMEの詳細
  }

  const state = useState<HousingState>('home-state', () => ({
    presetHousingHomes: [],
    presetHousingScenes: [],
    presetHousingHome: null,
  }))

  /**
   * プリセットハウジングHOMEの一覧取得
   * @params tutorialUse: true: チュートリアル用HOME false: チュートリアル用ではないHOME
   */
  const getPresetHousingHomes = async (
    tutorialUse: GetPresetHousingHomesRequest
  ): Promise<PresetHousingHome[]> => {
    try {
      const response = await homeRepository.get.getPresetHousingHomes(
        tutorialUse
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getPresetHousingHomesResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.presetHousingHomes = response.presetHousingHomes || []

      return response.presetHousingHomes
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * プリセットハウジングHOMEの詳細取得
   * @params id
   */
  const getPresetHousingHome = async (
    id: string
  ): Promise<PresetHousingHome> => {
    try {
      const response = await homeRepository.get.getPresetHousingHome({
        id,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getPresetHousingHomeResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.presetHousingHome = response.presetHousingHome || null

      return response.presetHousingHome
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  /**
   * プリセットハウジングシーン一覧取得
   */
  const getPresetHousingScenes = async (): Promise<PresetHousingScene[]> => {
    try {
      const response = await homeRepository.get.getPresetHousingScenes()

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getPresetHousingScenesResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.presetHousingScenes = response.presetHousingScenes || []

      return response.presetHousingScenes
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  return {
    state: readonly(state),
    getPresetHousingHomes,
    getPresetHousingHome,
    getPresetHousingScenes,
  }
}

export type HomeComposable = ReturnType<typeof useHome>

export const homeInjectionKey: InjectionKey<HomeComposable> =
  Symbol('home-composable')
